import { FC } from 'react'
import HighlightItems from './HighlightItems'

const HighlightSection: FC = () => {
   return (
      <section className="bg-[#EEE3D1]">
         <div
            className="container py-16 md:pt-24 md:pb-18 md:px-0 xl:pt-50 xl:pb-24 
            text-center xl:text-left text-black xl:flex xl:justify-between">
            <div className="pattern-divide mb-20 max-w-md mx-auto xl:mx-0 animate animate-slide-up">
               <h2 className="max-w-xs md:max-w-md mx-auto">Lezzet</h2>
               <p className="mt-4 md:mt-7">
                  Günün her saati farklı bir lezzet deneyimi sunan bobo franco,
                  misafirlerini avrupanın aperatif mutfağı ve rafine
                  içeceklerini Adana samiyeti ile buluşturan düşünülmüş bir
                  nokta. İşte onlardan bazıları
               </p>
            </div>
            <HighlightItems />
         </div>
      </section>
   )
}

export default HighlightSection
