import { FC } from 'react'
import Feature from './Feature'
// images
import mainImage1 from '../../assets/homepage/main_section_1.png'
import mainImage2 from '../../assets/homepage/main_section_2.png'

const Features: FC = () => {
   return (
      <div className="container flex flex-col xl:gap-30">
         <Feature
            imgSrc={mainImage1}
            heading="Atmosfer"
            paragraph="Şehrin kalbinde, rutinin koşuşturmasından uzak, tarzın ve sanatın buluştuğu gizli bir Akdeniz bahçesi"
         />
         <Feature
            imgSrc={mainImage2}
            heading="Keşfedin"
            paragraph="Benzer ruhların buluştuğu, müzik ve sohbetle sarılan yeşil bir atmosfer. Eğlence ve stilin her an bir araya geldiği gastronomik bir deneyim"
         />
      </div>
   )
}

export default Features
