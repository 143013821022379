import { FC } from 'react'
import styled from 'styled-components'
import Logo from '../assets/logo_black.svg'
import { Link } from 'react-router-dom'

const Footer: FC = () => {
   return (
      <footer className="bg-[#EEE3D1]">
         <div className="container py-20 text-center md:text-left md:flex animate animate-fade-in">
            <Link to="/" className="w-[6.46375rem] h-10">
               <img src={Logo} alt="Logo" />
            </Link>

            <div
               className="uppercase text-sm leading-7 font-normal text-black tracking-widest 
               md:ml-32 xl:ml-48 xl:flex">
               <div className="mt-10 mb-8 md:mt-0 xl:mb-0 xl:mr-44">
                  <div>
                     Cemalpaşa Mah.
                     <br />
                     Bahar Cd. No: 17
                  </div>
                  <div>SEYHAN / ADANA</div>
               </div>

               <div>
                  SALI - PAZAR
                  <br />
                  09:00-23:00
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer
