import { FC } from 'react'
import styled from 'styled-components'
import { IHighlightItem } from '../../interfaces/index.interface'

const HighlightWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 26.125rem;
   border-bottom: 1px solid rgba(255, 255, 255, 0.15); /* border-b-white/15 */
   margin-bottom: 1.5rem;
   animation: slide-up 1s ease-out;

   &:last-child {
      border-bottom: none;
      margin-bottom: 0;
   }

   @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      height: 7.5rem; /* md:h-30 */
   }

   @media (min-width: 1280px) {
      animation: none; /* xl:animate-none */
   }
`

const PictureWrapper = styled.picture`
   max-width: 20rem;
   height: 15.3125rem;
   margin-right: 0;
   position: relative;

   @media (min-width: 768px) {
      width: 8rem;
      height: 6rem;
      margin-right: 4rem; /* md:mr-16 */
   }

   &::after {
      content: '';
      display: none;

      @media (min-width: 768px) {
         display: block;
         position: absolute;
         top: 1rem;
         right: -2rem;
         width: 2rem;
         height: 1px;
         background-color: #9d8e8c; /* bg-primary-beaver */
      }
   }
`

const Image = styled.img`
   width: 100%;
   height: 100%;
   object-fit: cover;
`

const TextWrapper = styled.div`
   padding-top: 2.25rem; /* pt-9 */

   @media (min-width: 768px) {
      padding-top: 0;
      text-align: left;
   }
`

const Heading = styled.h3`
   font-size: 1.25rem;
   font-weight: bold;
`

const Paragraph = styled.p`
   margin-top: 0.5rem;
   max-width: 20rem;
   font-size: 1rem;
   line-height: 1.625;

   @media (min-width: 768px) {
      font-size: 1rem; /* md:text-base */
      line-height: 1.625; /* md:leading-[1.625] */
   }
`

const HighlightItem: FC<IHighlightItem> = ({ imgSrc, heading, paragraph }) => {
   return (
      <HighlightWrapper>
         <PictureWrapper>
            <Image src={imgSrc} alt={`${heading} Img`} />
         </PictureWrapper>
         <TextWrapper>
            <Heading>{heading}</Heading>
            <Paragraph>{paragraph}</Paragraph>
         </TextWrapper>
      </HighlightWrapper>
   )
}

export default HighlightItem
