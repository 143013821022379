// images
import storeImage from '../assets/homepage/store.png'
import barImage from '../assets/homepage/bar.png'
import eventsImage from '../assets/homepage/events.png'

// Static data for events section
export const eventsData = [
   {
      name: 'Store',
      paragraph:
         'bobo franco zamansız mimarisi ile sanat eserleri ve yaratıcılığın buluştuğu, Adana merkezli çok amaçlı yaratıcı bir alandır. bobo franco’nun store’u koleksiyon değeri taşıyan, modern vintage ve yeni mobilya parçalarını, yerel sanatçıların çağdaş sanat eserlerini ve benzersiz tasarım objelerini sunarken, yaratıcılığın gelişebileceği özel bir alan yaratmaktadır.',
      img: storeImage,
   },
   {
      name: 'Bar',
      paragraph:
         'bobo franco’nun barı, sanatı ve tasarımı kutlarken aynı zamanda lezzetli içecekler sunan bir buluşma noktasıdır. Modern ve samimi bir atmosferde özenle hazırlanmış kokteyller, kahve spesiyalleri ve yerel lezzetler ile hem rahatlayabilir hem de yaratıcılığınızı besleyebilirsiniz.',
      img: barImage,
   },
   {
      name: 'Events',
      paragraph:
         "bobo franco'da düzenlenen pop-up partiler, workshoplar, sanat sergileri ve tasarım sohbetleri hem bobo franco üyelerini, hem sanatçıları, hem de sanatseverleri bir araya getirerek yaratıcılığı besler. İster bir sanat etkinliği düzenlemek için isterse yaratıcı projeleriniz için bir alan arıyor olun, bobo franco'nun dinamik ve eğlenceli atmosferi ilham verici bir deneyim sunar.",
      img: eventsImage,
   },
]
