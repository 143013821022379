import { FC } from 'react'
import HighlightItem from './HighlightItem'
// Images
import secondSectionImage1 from '../../assets/homepage/second_section_1.png'
import secondSectionImage2 from '../../assets/homepage/second_section_2.png'
import secondSectionImage3 from '../../assets/homepage/second_section_3.png'

const HighlightItems: FC = () => {
   return (
      <div
         className="mt-14 mx-auto xl:mx-0 max-w-[35.8125rem] xl:max-w-[33.75rem]
         animate animate-fade-in">
         <HighlightItem
            imgSrc={secondSectionImage1}
            heading="Truffle Risotto"
            paragraph="Trüf mantarıyla tatlandırılmış risottomuz, güney İtalya'nın klasik lezzetini zarif bir şekilde yansıtıyor"
         />
         <HighlightItem
            imgSrc={secondSectionImage2}
            heading="Franco’s Kalamari"
            paragraph="Akdeniz’in özgün taze kalamarı ile kendinizi deniz kenarındaki bir lezzet kaçamağında hissedeceksiniz"
         />
         <HighlightItem
            imgSrc={secondSectionImage3}
            heading="The Perfect Espresso Martini"
            paragraph="Yoğun kahve aroması ve hafif kremamsı dokusuyla dinlendirici bir keyif sunar"
         />
      </div>
   )
}

export default HighlightItems
