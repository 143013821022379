import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import mainVideo from '../assets/main_video.mp4'
import Button from './Buttons'
import Parallax from './Parallax'
import Logo from '../assets/logo.svg'

// Keyframes for letter animation
const fadeIn = keyframes`
   0% {
      opacity: 0;
      transform: translateY(20px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
`

// Styled components
const Section = styled.section`
   color: white;
   position: relative;
   overflow: hidden;
   height: 100vh;
`

const VideoWrapper = styled.div`
   width: 100%;
   height: 100%;
   position: relative;

   video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      z-index: 0;

      @media (min-width: 640px) {
         object-position: center 50%; /* sm:object-[center_50%] */
      }

      @media (min-width: 768px) {
         object-position: center 10%; /* md:object-[center_10%] */
      }
   }
`

const GradientOverlay = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
   );
   z-index: 1;
`

const ContentWrapper = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 2; /* Ensure content is above gradient */
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;

   @media (min-width: 768px) {
      top: 50%; /* Ortada tutmak için %50 */
      transform: translate(-50%, -50%); /* Tam ortalama */
   }

   @media (min-width: 1280px) {
      left: 50%;
      transform: translate(-50%, -50%); /* Büyük ekranlarda da tam ortalama */
   }
`

const TextWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   animation: fade-in 1s ease-out;

   @media (min-width: 1280px) {
      align-items: center;
      text-align: center;
   }
`

const Bobo = styled.div`
   font-family: 'Sitka Small', serif;
   font-size: 5rem;
   line-height: 1;
   position: relative;
   margin-bottom: 3rem;
   display: flex;
   right: 2rem;
   user-select: none;

   span {
      display: inline-block;
      opacity: 0;
      animation: ${fadeIn} 0.5s forwards;
   }

   span:nth-child(1) {
      animation-delay: 0s;
   }
   span:nth-child(2) {
      animation-delay: 1.5s;
   }
   span:nth-child(3) {
      animation-delay: 0.2s;
   }
   span:nth-child(4) {
      animation-delay: 1.5s;
   }

   @media (min-width: 768px) {
      font-size: 7rem;
   }
`

const Franco = styled.div`
   font-family: 'Sitka Small', serif;
   font-size: 5rem;
   position: absolute;
   top: 3.7rem; /* Adjusts the vertical position below 'bobo' */
   left: 3.7rem; /* Adjusts the horizontal offset to be slightly to the right */
   display: flex;

   @media (min-width: 768px) {
      padding-top: 2.5rem; /* md:pt-10 */
      padding-bottom: 3.25rem; /* md:pb-13 */
   }

   span {
      display: inline-block;
      opacity: 0;
      animation: ${fadeIn} 0.5s forwards;
   }

   span:nth-child(1) {
      animation-delay: 0.4s;
   }
   span:nth-child(2) {
      animation-delay: 0.5s;
   }
   span:nth-child(3) {
      animation-delay: 0.6s;
   }
   span:nth-child(4) {
      animation-delay: 0.7s;
   }
   span:nth-child(5) {
      animation-delay: 0.8s;
   }
   span:nth-child(6) {
      animation-delay: 1.5s;
   }

   @media (min-width: 768px) {
      font-size: 7rem;
   }
`

const Paragraph = styled.p`
   padding-top: 1.25rem; /* pt-5 */
   padding-bottom: 3rem; /* pb-12 */

   @media (min-width: 768px) {
      padding-top: 2.5rem; /* md:pt-10 */
      padding-bottom: 3.25rem; /* md:pb-13 */
   }

   @media (min-width: 1280px) {
      padding-top: 1.5rem; /* xl:pt-6 */
      padding-bottom: 2.5rem; /* xl:pb-10 */
      max-width: 32rem; /* xl:max-w-md */
   }
`

const HeroSection: FC = () => {
   return (
      <Section>
         <Parallax>
            {/* Grey Gradient Overlay */}
            <GradientOverlay />
            <VideoWrapper>
               {/* Video */}
               <video autoPlay loop muted playsInline>
                  <source src={mainVideo} type="video/mp4" />
                  Your browser does not support the video tag.
               </video>
            </VideoWrapper>
         </Parallax>

         {/* Content */}
         <ContentWrapper>
            <TextWrapper>
               <Bobo>
                  <span>b</span>
                  <span>o</span>
                  <span>b</span>
                  <span>o</span>
                  <Franco>
                     <span>f</span>
                     <span>r</span>
                     <span>a</span>
                     <span>n</span>
                     <span>c</span>
                     <span>o</span>
                  </Franco>
               </Bobo>
               {/* <Paragraph>
                  Experience our seasonal menu in beautiful country
                  surroundings. Eat the freshest produce from the comfort of our
                  farmhouse.
               </Paragraph> */}
               {/* <Button content="Rezervasyon" link="booking" /> */}
            </TextWrapper>
         </ContentWrapper>
      </Section>
   )
}

export default HeroSection
